import api from '@/api.js'

export default {

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	login(context, credentials) {
		return new Promise((resolve, reject) => {
			api.post('login', {
				username: credentials.username,
				password: credentials.password,
			})
			.then(response => {
				api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.session.access_token
				context.commit('save_session', response.data.session)
				context.commit('set_session', {
					account: response.data.account,
					privileges: response.data.privileges
				})
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},


	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	recover(context, username) {
		return new Promise((resolve, reject) => {
			api.post('recover', {
				username: username
			})
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	check_reset_token(context, token) {
		return new Promise((resolve, reject) => {
			api.post('check_reset_token', {
				token: token
			})
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	check_pass_token(context, token) {
		return new Promise((resolve, reject) => {
			api.post('check_pass_token', {
				token: token
			})
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	check_cargo_pass_token(context, token) {
		return new Promise((resolve, reject) => {
			api.post('check_cargo_pass_token', {
				token: token
			})
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	check_corporate_pass_token(context, token) {
		return new Promise((resolve, reject) => {
			api.post('check_corporate_pass_token', {
				token: token
			})
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	reset(context, data) {
		return new Promise((resolve, reject) => {
			api.post('reset', {
				token: data.token,
				password: data.password
			})
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	update_password(context, data) {
		return new Promise((resolve, reject) => {
			api.put('users/'+data.user_id+'/password', {
				password: data.password
			})
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},



	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	refresh_token(context) {
		return new Promise((resolve, reject) => {
			api.post('refresh_token', {
				refresh_token: context.state.user.data.session.refresh_token
			})
			.then(response => {
				api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token
				context.commit('save_session', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_session_privileges(context) {
		context.state.user.data.account.loading = true
		context.state.user.data.privileges.loading = true
		api.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.user.data.session.access_token
		return new Promise((resolve, reject) => {
			api.get('session')
			.then(response => {
				context.commit('set_session', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_privileges(context, user_id) {
		context.state.privileges = { loading: true, data: { user: [], available: [], enabled: [] } }
		return new Promise((resolve, reject) => {
			api.get('users/'+user_id+'/privileges')
			.then(response => {
				context.commit('set_privileges', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_privilege_list(context) {
		context.state.privilege_list = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('privileges')
			.then(response => {
				context.commit('set_privilege_list', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	logout(context) {
		return new Promise((resolve, reject) => {
			context.commit('destroy_session')
			api.get('logout')
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},


	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_users(context, query) {
		context.state.users.loading = true
		return new Promise((resolve, reject) => {
			api.get('users', {
				params: {
					type: query ? query.type : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
			.then(response => {
				context.state.users.loading = false
				context.commit('set_users', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.users.loading = false
				context.commit('set_users', [])
				reject(error)
			})
		})
	},
	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_current_user(context, user_id) {
		context.state.current_user.loading = true
		return new Promise((resolve, reject) => {
			api.get('users/'+user_id)
			.then(response => {
				context.state.current_user.loading = false
				context.commit('set_current_user', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.current_user.loading = false
				context.commit('set_current_user', [])
				reject(error)
			})
		})
	},


	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_contractors(context, query) {
		context.state.contractors.loading = true
		return new Promise((resolve, reject) => {
			api.get('contractors', {
				params: {
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.contractors.loading = false
					context.commit('set_contractors', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.contractors.loading = false
					context.commit('set_contractors', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_contractor(context, id) {
		context.state.contractor.loading = true
		return new Promise((resolve, reject) => {
			api.get('contractors/'+id)
				.then(response => {
					context.state.contractor.loading = false
					context.commit('set_contractor', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.contractor.loading = false
					context.commit('reset_contractor')
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_subcontractors(context, query) {
		context.state.subcontractors.loading = true
		return new Promise((resolve, reject) => {
			api.get('subcontractors', {
				params: {
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.subcontractors.loading = false
					context.commit('set_subcontractors', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.subcontractors.loading = false
					context.commit('set_subcontractors', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_subcontractor(context, id) {
		context.state.subcontractor.loading = true
		return new Promise((resolve, reject) => {
			api.get('subcontractors/'+id)
				.then(response => {
					context.state.subcontractor.loading = false
					context.commit('set_subcontractor', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.subcontractor.loading = false
					context.commit('reset_subcontractor')
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_services(context, query) {
		context.state.services.loading = true
		return new Promise((resolve, reject) => {
			api.get('services', {
				params: {
					type: query ? query.type : null,
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.services.loading = false
					context.commit('set_services', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.services.loading = false
					context.commit('set_services', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_service(context, id) {
		context.state.service.loading = true
		return new Promise((resolve, reject) => {
			api.get('services/'+id)
				.then(response => {
					context.state.service.loading = false
					context.commit('set_service', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.service.loading = false
					context.commit('reset_service')
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_workers(context, query) {
		context.state.workers.loading = true
		return new Promise((resolve, reject) => {
			api.get('workers', {
				params: {
					type: query ? query.type : null,
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.workers.loading = false
					context.commit('set_workers', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.workers.loading = false
					context.commit('set_workers', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_internal_workers_with_shifts(context) {
		context.state.workers.loading = true
		return new Promise((resolve, reject) => {
			api.get('workers?with_shifts')
				.then(response => {
					context.state.workers.loading = false
					context.commit('set_workers', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.workers.loading = false
					context.commit('set_workers', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_internal_workers(context) {
		context.state.workers.loading = true
		return new Promise((resolve, reject) => {
			api.get('workers?internal_only')
				.then(response => {
					context.state.workers.loading = false
					context.commit('set_workers', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.workers.loading = false
					context.commit('set_workers', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_worker(context, id) {
		context.state.worker.loading = true
		return new Promise((resolve, reject) => {
			api.get('workers/'+id)
				.then(response => {
					context.state.worker.loading = false
					context.commit('set_worker', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.worker.loading = false
					context.commit('reset_worker')
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_vehicles(context, query) {
		context.state.vehicles.loading = true
		return new Promise((resolve, reject) => {
			api.get('vehicles', {
				params: {
					type: query ? query.type : null,
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.vehicles.loading = false
					context.commit('set_vehicles', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.vehicles.loading = false
					context.commit('set_vehicles', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_vehicle(context, id) {
		context.state.vehicle.loading = true
		return new Promise((resolve, reject) => {
			api.get('vehicles/'+id)
				.then(response => {
					context.state.vehicle.loading = false
					context.commit('set_vehicle', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.vehicle.loading = false
					context.commit('reset_vehicle')
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_contractor_requirements(context, contractor_id) {
		context.state.contractor_requirements = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('contractors/'+contractor_id+'/requirements')
				.then(response => {
					context.state.contractor_requirements.loading = false
					context.commit('set_contractor_requirements', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.contractor_requirements.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_contractor_workers(context, contractor_id) {
		context.state.contractor_workers = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('contractors/'+contractor_id+'/workers')
				.then(response => {
					context.state.contractor_workers.loading = false
					context.commit('set_contractor_workers', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.contractor_workers.loading = false
					context.commit('set_contractor_workers', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_subcontractor_requirements(context, subcontractor_id) {
		context.state.subcontractor_requirements = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('subcontractors/'+subcontractor_id+'/requirements')
				.then(response => {
					context.state.subcontractor_requirements.loading = false
					context.commit('set_subcontractor_requirements', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.subcontractor_requirements.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_subcontractor_workers(context, subcontractor_id) {
		context.state.subcontractor_workers = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('subcontractors/'+subcontractor_id+'/workers')
				.then(response => {
					context.state.subcontractor_workers.loading = false
					context.commit('set_subcontractor_workers', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.subcontractor_workers.loading = false
					context.commit('set_subcontractor_workers', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_service_requirements(context, service_id) {
		context.state.service_requirements = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('services/'+service_id+'/requirements')
				.then(response => {
					context.state.service_requirements.loading = false
					context.commit('set_service_requirements', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.service_requirements.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_service_extensions(context, service_id) {
		context.state.service_extensions = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('services/'+service_id+'/extensions')
				.then(response => {
					context.state.service_extensions.loading = false
					context.commit('set_service_extensions', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.service_extensions.loading = false
					context.commit('set_service_extensions', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_service_workers(context, service_id) {
		context.state.service_workers = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('services/'+service_id+'/workers')
				.then(response => {
					context.state.service_workers.loading = false
					context.commit('set_service_workers', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.service_workers.loading = false
					context.commit('set_service_workers', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_extension_start(context, service_id) {
		context.state.extension_start = { loading: true, data: '' }
		return new Promise((resolve, reject) => {
			api.get('services/'+service_id+'/extensions/start')
				.then(response => {
					context.state.extension_start.loading = false
					context.commit('set_extension_start', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.extension_start.loading = false
					context.commit('set_extension_start', '')
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_worker_requirements(context, worker_id) {
		context.state.worker_requirements = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('workers/'+worker_id+'/requirements')
				.then(response => {
					context.state.worker_requirements.loading = false
					context.commit('set_worker_requirements', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.worker_requirements.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_worker_restrictions(context, worker_id) {
		context.state.worker_restrictions = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('workers/'+worker_id+'/restrictions')
				.then(response => {
					context.state.worker_restrictions.loading = false
					context.commit('set_worker_restrictions', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.worker_restrictions.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_worker_restriction_types(context) {
		context.state.worker_restriction_types = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('restriction_types')
				.then(response => {
					context.state.worker_restriction_types.loading = false
					context.commit('set_worker_restriction_types', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.worker_restriction_types.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_vehicle_requirements(context, vehicle_id) {
		context.state.vehicle_requirements = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('vehicles/'+vehicle_id+'/requirements')
				.then(response => {
					context.state.vehicle_requirements.loading = false
					context.commit('set_vehicle_requirements', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.vehicle_requirements.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_regions(context) {
		context.state.regions = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('regions')
				.then(response => {
					context.state.regions.loading = false
					context.commit('set_regions', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.regions.loading = false
					context.commit('set_regions', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_communes(context) {
		context.state.communes = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('communes')
				.then(response => {
					context.state.communes.loading = false
					context.commit('set_communes', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.communes.loading = false
					context.commit('set_communes', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_shifts(context) {
		context.state.shifts = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('shifts')
				.then(response => {
					context.state.shifts.loading = false
					context.commit('set_shifts', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.shifts.loading = false
					context.commit('set_shifts', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_service_administrators(context) {
		context.state.service_administrators = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('services/administrators')
				.then(response => {
					context.state.service_administrators.loading = false
					context.commit('set_service_administrators', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.service_administrators.loading = false
					context.commit('set_service_administrators', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_vehicle_types(context) {
		context.state.vehicle_types = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('vehicles/types')
				.then(response => {
					context.state.vehicle_types.loading = false
					context.commit('set_vehicle_types', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.vehicle_types.loading = false
					context.commit('set_vehicle_types', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_passes(context, query) {
		context.state.passes.loading = true
		return new Promise((resolve, reject) => {
			api.get('passes', {
				params: {
					type: query ? query.type : null,
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.passes.loading = false
					context.commit('set_passes', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.passes.loading = false
					context.commit('set_passes', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_cargo_passes(context, query) {
		context.state.cargo_passes.loading = true
		return new Promise((resolve, reject) => {
			api.get('cargo_passes', {
				params: {
					type: query ? query.type : null,
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.cargo_passes.loading = false
					context.commit('set_cargo_passes', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.cargo_passes.loading = false
					context.commit('set_cargo_passes', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_corporate_passes(context, query) {
		context.state.corporate_passes.loading = true
		return new Promise((resolve, reject) => {
			api.get('corporate_passes', {
				params: {
					type: query ? query.type : null,
					active: query ? query.active : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
				.then(response => {
					context.state.corporate_passes.loading = false
					context.commit('set_corporate_passes', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.corporate_passes.loading = false
					context.commit('set_corporate_passes', [])
					reject(error)
				})
		})
	},

	get_date_expirations(context, date) {
		context.state.current_date_expirations = { loading: true, data: [], date: date }
		return new Promise((resolve, reject) => {
			api.post('expirations', { date: date })
				.then(response => {
					if (response.data.success) {
						context.commit('set_current_date_expirations', response.data.object); resolve(response)
					}
					context.state.current_date_expirations.loading = false
				})
				.catch(error => { reject(error) })
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_user_logs(context, query) {
		context.state.user_logs.loading = true
		return new Promise((resolve, reject) => {
			api.get('logs/users', {
				params: {
					type: query ? query.type : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
			.then(response => {
				context.state.user_logs.loading = false
				context.commit('set_user_logs', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.user_logs.loading = false
				context.commit('set_user_logs', [])
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_requirements_report_options(context, token) {
		context.state.requirements_report_options.loading = true
		return new Promise((resolve, reject) => {
			api.post('stats/requirements/options', { token: token })
				.then(response => {
					context.state.requirements_report_options.loading = false
					context.commit('set_requirements_report_options', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.requirements_report_options.loading = false
					context.commit('set_requirements_report_options', [])
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_controls(context, query) {
		context.state.controls.loading = true
		return new Promise((resolve, reject) => {
			api.get('controls', {
				params: {
					type: query ? query.type : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
			.then(response => {
				context.state.controls.loading = false
				context.commit('set_controls', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.controls.loading = false
				context.commit('set_controls', [])
				reject(error)
			})
		})
	},


	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_grids(context, query) {
		context.state.grids.loading = true
		return new Promise((resolve, reject) => {
			api.get('grids', {
				params: {
					type: query ? query.type : null,
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					filter: query && query.filter.length > 0 ? query.filter : null
				}
			})
			.then(response => {
				context.state.grids.loading = false
				context.commit('set_grids', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.grids.loading = false
				context.commit('set_grids', [])
				reject(error)
			})
		})
	},


	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	get_approvers(context) {
		context.state.approvers.loading = true
		return new Promise((resolve, reject) => {
			api.get('approvers')
			.then(response => {
				context.state.approvers.loading = false
				context.commit('set_approvers', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.approvers.loading = false
				context.commit('set_approvers', [])
				reject(error)
			})
		})
	},

	get_global_parameters(context) {
		context.state.global_parameters = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('global_parameters')
			.then(response => {
				context.commit('set_global_parameters', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	countries(context) {
		context.state.countries.loading = true
		return new Promise((resolve, reject) => {
			api.get('countries')
				.then(response => {
					context.state.countries.loading = false
					context.commit('countries', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.countries.loading = false
					reject(error)
				})
		})
	},

	/**
	* 
	* @param {*} context 
	* @returns 
	*/
	migration_statuses(context) {
		context.state.migration_statuses.loading = true
		return new Promise((resolve, reject) => {
			api.get('migration_statuses')
				.then(response => {
					context.state.migration_statuses.loading = false
					context.commit('migration_statuses', response.data)
					resolve(response)
				})
				.catch(error => {
					context.state.migration_statuses.loading = false
					reject(error)
				})
		})
	},

}