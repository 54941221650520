export default {
    session(state) {
        return state.user.data.session.access_token !== null
    },
    internal(state) {
        return state.user.data.account.data.internal
    },
    session_loading(state) {
        return state.user.data.account.loading || state.user.data.privileges.loading
    },

    /* privileges */
    prv_contractors: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors') != -1 ? true : false },
    prv_contractors_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_read') != -1 ? true : false },
    prv_contractors_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_create') != -1 ? true : false },
    prv_contractors_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_update') != -1 ? true : false },
    prv_contractors_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_delete') != -1 ? true : false },
    prv_contractors_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_requirements_read') != -1 ? true : false },
    prv_contractors_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_requirements_upload') != -1 ? true : false },
    prv_contractors_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_requirements_review') != -1 ? true : false },
    prv_contractors_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_requirements_disable') != -1 ? true : false },
    prv_contractors_risk_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_risk_requirements_read') != -1 ? true : false },
    prv_contractors_risk_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_risk_requirements_upload') != -1 ? true : false },
    prv_contractors_risk_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_risk_requirements_review') != -1 ? true : false },
    prv_contractors_risk_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractors_risk_requirements_disable') != -1 ? true : false },
    prv_subcontractors: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors') != -1 ? true : false },
    prv_subcontractors_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_read') != -1 ? true : false },
    prv_subcontractors_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_create') != -1 ? true : false },
    prv_subcontractors_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_update') != -1 ? true : false },
    prv_subcontractors_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_delete') != -1 ? true : false },
    prv_subcontractors_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_requirements_read') != -1 ? true : false },
    prv_subcontractors_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_requirements_upload') != -1 ? true : false },
    prv_subcontractors_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_requirements_review') != -1 ? true : false },
    prv_subcontractors_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_requirements_disable') != -1 ? true : false },
    prv_subcontractors_risk_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_risk_requirements_read') != -1 ? true : false },
    prv_subcontractors_risk_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_risk_requirements_upload') != -1 ? true : false },
    prv_subcontractors_risk_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_risk_requirements_review') != -1 ? true : false },
    prv_subcontractors_risk_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractors_risk_requirements_disable') != -1 ? true : false },
    prv_service_orders: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders') != -1 ? true : false },
    prv_service_orders_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_read') != -1 ? true : false },
    prv_service_orders_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_create') != -1 ? true : false },
    prv_service_orders_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_update') != -1 ? true : false },
    prv_service_orders_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_delete') != -1 ? true : false },
    prv_service_orders_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_requirements_read') != -1 ? true : false },
    prv_service_orders_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_requirements_upload') != -1 ? true : false },
    prv_service_orders_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_requirements_review') != -1 ? true : false },
    prv_service_orders_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_requirements_disable') != -1 ? true : false },
    prv_service_orders_workers_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_workers_read') != -1 ? true : false },
    prv_service_orders_workers_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_workers_create') != -1 ? true : false },
    prv_service_orders_workers_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_workers_review') != -1 ? true : false },
    prv_service_orders_extensions_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_extensions_read') != -1 ? true : false },
    prv_service_orders_extensions_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_extensions_create') != -1 ? true : false },
    prv_service_orders_physical_folder_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_service_orders_physical_folder_manage') != -1 ? true : false },
    prv_contracts: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts') != -1 ? true : false },
    prv_contracts_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_read') != -1 ? true : false },
    prv_contracts_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_create') != -1 ? true : false },
    prv_contracts_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_update') != -1 ? true : false },
    prv_contracts_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_delete') != -1 ? true : false },
    prv_contracts_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_requirements_read') != -1 ? true : false },
    prv_contracts_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_requirements_upload') != -1 ? true : false },
    prv_contracts_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_requirements_review') != -1 ? true : false },
    prv_contracts_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_requirements_disable') != -1 ? true : false },
    prv_contracts_workers_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_workers_read') != -1 ? true : false },
    prv_contracts_workers_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_workers_create') != -1 ? true : false },
    prv_contracts_workers_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_workers_review') != -1 ? true : false },
    prv_contracts_extensions_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_extensions_read') != -1 ? true : false },
    prv_contracts_extensions_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_extensions_create') != -1 ? true : false },
    prv_contracts_physical_folder_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contracts_physical_folder_manage') != -1 ? true : false },
    prv_warranties: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties') != -1 ? true : false },
    prv_warranties_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_read') != -1 ? true : false },
    prv_warranties_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_create') != -1 ? true : false },
    prv_warranties_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_update') != -1 ? true : false },
    prv_warranties_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_delete') != -1 ? true : false },
    prv_warranties_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_requirements_read') != -1 ? true : false },
    prv_warranties_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_requirements_upload') != -1 ? true : false },
    prv_warranties_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_requirements_review') != -1 ? true : false },
    prv_warranties_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_requirements_disable') != -1 ? true : false },
    prv_warranties_workers_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_workers_read') != -1 ? true : false },
    prv_warranties_workers_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_workers_create') != -1 ? true : false },
    prv_warranties_workers_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_workers_review') != -1 ? true : false },
    prv_warranties_extensions_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_extensions_read') != -1 ? true : false },
    prv_warranties_extensions_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_extensions_create') != -1 ? true : false },
    prv_warranties_physical_folder_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_warranties_physical_folder_manage') != -1 ? true : false },
    prv_subcontracts: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts') != -1 ? true : false },
    prv_subcontracts_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_read') != -1 ? true : false },
    prv_subcontracts_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_create') != -1 ? true : false },
    prv_subcontracts_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_update') != -1 ? true : false },
    prv_subcontracts_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_delete') != -1 ? true : false },
    prv_subcontracts_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_requirements_read') != -1 ? true : false },
    prv_subcontracts_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_requirements_upload') != -1 ? true : false },
    prv_subcontracts_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_requirements_review') != -1 ? true : false },
    prv_subcontracts_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_requirements_disable') != -1 ? true : false },
    prv_subcontracts_workers_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_workers_read') != -1 ? true : false },
    prv_subcontracts_workers_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_workers_create') != -1 ? true : false },
    prv_subcontracts_workers_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_workers_review') != -1 ? true : false },
    prv_subcontracts_extensions_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_extensions_read') != -1 ? true : false },
    prv_subcontracts_extensions_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_extensions_create') != -1 ? true : false },
    prv_subcontracts_physical_folder_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontracts_physical_folder_manage') != -1 ? true : false },
    prv_internal_workers: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_workers') != -1 ? true : false },
    prv_internal_workers_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_workers_read') != -1 ? true : false },
    prv_internal_workers_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_workers_create') != -1 ? true : false },
    prv_internal_workers_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_workers_update') != -1 ? true : false },
    prv_internal_workers_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_workers_delete') != -1 ? true : false },
    prv_internal_workers_restrictions_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_workers_restrictions_read') != -1 ? true : false },
    prv_internal_workers_restrictions_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_workers_restrictions_create') != -1 ? true : false },
    prv_contractor_workers: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers') != -1 ? true : false },
    prv_contractor_workers_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_read') != -1 ? true : false },
    prv_contractor_workers_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_create') != -1 ? true : false },
    prv_contractor_workers_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_update') != -1 ? true : false },
    prv_contractor_workers_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_delete') != -1 ? true : false },
    prv_contractor_workers_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_requirements_read') != -1 ? true : false },
    prv_contractor_workers_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_requirements_upload') != -1 ? true : false },
    prv_contractor_workers_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_requirements_review') != -1 ? true : false },
    prv_contractor_workers_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_requirements_disable') != -1 ? true : false },
    prv_contractor_workers_driver_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_driver_requirements_read') != -1 ? true : false },
    prv_contractor_workers_driver_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_driver_requirements_upload') != -1 ? true : false },
    prv_contractor_workers_driver_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_driver_requirements_review') != -1 ? true : false },
    prv_contractor_workers_driver_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_driver_requirements_disable') != -1 ? true : false },
    prv_contractor_workers_coppermark_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_coppermark_requirements_read') != -1 ? true : false },
    prv_contractor_workers_coppermark_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_coppermark_requirements_upload') != -1 ? true : false },
    prv_contractor_workers_coppermark_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_coppermark_requirements_review') != -1 ? true : false },
    prv_contractor_workers_coppermark_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_workers_coppermark_requirements_disable') != -1 ? true : false },
    prv_subcontractor_workers: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers') != -1 ? true : false },
    prv_subcontractor_workers_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_read') != -1 ? true : false },
    prv_subcontractor_workers_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_create') != -1 ? true : false },
    prv_subcontractor_workers_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_update') != -1 ? true : false },
    prv_subcontractor_workers_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_delete') != -1 ? true : false },
    prv_subcontractor_workers_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_requirements_read') != -1 ? true : false },
    prv_subcontractor_workers_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_requirements_upload') != -1 ? true : false },
    prv_subcontractor_workers_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_requirements_review') != -1 ? true : false },
    prv_subcontractor_workers_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_requirements_disable') != -1 ? true : false },
    prv_subcontractor_workers_driver_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_driver_requirements_read') != -1 ? true : false },
    prv_subcontractor_workers_driver_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_driver_requirements_upload') != -1 ? true : false },
    prv_subcontractor_workers_driver_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_driver_requirements_review') != -1 ? true : false },
    prv_subcontractor_workers_driver_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_driver_requirements_disable') != -1 ? true : false },
    prv_subcontractor_workers_coppermark_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_coppermark_requirements_read') != -1 ? true : false },
    prv_subcontractor_workers_coppermark_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_coppermark_requirements_upload') != -1 ? true : false },
    prv_subcontractor_workers_coppermark_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_coppermark_requirements_review') != -1 ? true : false },
    prv_subcontractor_workers_coppermark_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_workers_coppermark_requirements_disable') != -1 ? true : false },
    prv_internal_vehicles: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_vehicles') != -1 ? true : false },
    prv_internal_vehicles_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_vehicles_read') != -1 ? true : false },
    prv_internal_vehicles_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_vehicles_create') != -1 ? true : false },
    prv_internal_vehicles_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_vehicles_update') != -1 ? true : false },
    prv_internal_vehicles_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_internal_vehicles_delete') != -1 ? true : false },
    prv_contractor_vehicles: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles') != -1 ? true : false },
    prv_contractor_vehicles_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_read') != -1 ? true : false },
    prv_contractor_vehicles_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_create') != -1 ? true : false },
    prv_contractor_vehicles_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_update') != -1 ? true : false },
    prv_contractor_vehicles_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_delete') != -1 ? true : false },
    prv_contractor_vehicles_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_requirements_read') != -1 ? true : false },
    prv_contractor_vehicles_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_requirements_upload') != -1 ? true : false },
    prv_contractor_vehicles_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_requirements_review') != -1 ? true : false },
    prv_contractor_vehicles_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_contractor_vehicles_requirements_disable') != -1 ? true : false },
    prv_subcontractor_vehicles: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles') != -1 ? true : false },
    prv_subcontractor_vehicles_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_read') != -1 ? true : false },
    prv_subcontractor_vehicles_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_create') != -1 ? true : false },
    prv_subcontractor_vehicles_update: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_update') != -1 ? true : false },
    prv_subcontractor_vehicles_delete: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_delete') != -1 ? true : false },
    prv_subcontractor_vehicles_requirements_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_requirements_read') != -1 ? true : false },
    prv_subcontractor_vehicles_requirements_upload: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_requirements_upload') != -1 ? true : false },
    prv_subcontractor_vehicles_requirements_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_requirements_review') != -1 ? true : false },
    prv_subcontractor_vehicles_requirements_disable: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_subcontractor_vehicles_requirements_disable') != -1 ? true : false },
    prv_passes: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_passes') != -1 ? true : false },
    prv_passes_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_passes_read') != -1 ? true : false },
    prv_passes_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_passes_create') != -1 ? true : false },
    prv_passes_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_passes_review') != -1 ? true : false },
    prv_reports: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports') != -1 ? true : false },
    prv_reports_smart_search: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_smart_search') != -1 ? true : false },
    prv_reports_controls: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_controls') != -1 ? true : false },
    prv_reports_monitor: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_monitor') != -1 ? true : false },
    prv_reports_active: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_active') != -1 ? true : false },
    prv_reports_expirations: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_expirations') != -1 ? true : false },
    prv_reports_statistics: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_statistics') != -1 ? true : false },
    prv_reports_tables: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_tables') != -1 ? true : false },
    prv_reports_testing: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_testing') != -1 ? true : false },
    prv_reports_testing_setting: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_reports_testing_setting') != -1 ? true : false },
    prv_system: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system') != -1 ? true : false },
    prv_system_users_view: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_users_view') != -1 ? true : false },
    prv_system_users_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_users_manage') != -1 ? true : false },
    prv_system_shifts_view: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_shifts_view') != -1 ? true : false },
    prv_system_shifts_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_shifts_manage') != -1 ? true : false },
    prv_system_multiworkers_view: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_multiworkers_view') != -1 ? true : false },
    prv_system_multiworkers_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_multiworkers_manage') != -1 ? true : false },
    prv_system_settings_view: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_settings_view') != -1 ? true : false },
    prv_system_settings_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_settings_manage') != -1 ? true : false },
    prv_system_control_fixer_view: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_control_fixer_view') != -1 ? true : false },
    prv_system_control_fixer_manage: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_control_fixer_manage') != -1 ? true : false },
    prv_system_requirements_manager: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_requirements_manager') != -1 ? true : false },
    prv_system_global_parameters: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_system_global_parameters') != -1 ? true : false },
    prv_cargo_passes: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_cargo_passes') != -1 ? true : false },
    prv_cargo_passes_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_cargo_passes_read') != -1 ? true : false },
    prv_cargo_passes_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_cargo_passes_create') != -1 ? true : false },
    prv_cargo_passes_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_cargo_passes_review') != -1 ? true : false },
    prv_corporate_passes: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_corporate_passes') != -1 ? true : false },
    prv_corporate_passes_read: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_corporate_passes_read') != -1 ? true : false },
    prv_corporate_passes_create: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_corporate_passes_create') != -1 ? true : false },
    prv_corporate_passes_review: state => { return state.user.data.privileges.data.findIndex(prv => prv.name === 'prv_corporate_passes_review') != -1 ? true : false },
}