<template>
	<div>
		<b-alert variant="warning">
			<p class="text-size-12 mb-0"><i class="fas fa-exclamation-triangle mr-2"></i><strong>Advertencia</strong></p>
			<p class="text-size-12 mb-0">Este reporte se encuentra en proceso de modificación y puede presentar errores.</p>
		</b-alert>
		<b-alert variant="primary">
			<p class="text-size-12 mb-0"><i class="fas fa-info-circle mr-2"></i><strong>Información</strong></p>
			<p class="text-size-12 mb-0">El sistema valida la asistencia de cada día bajo las siguientes consideraciones:</p>
			<ul class="text-size-12 mb-0">
				<li>Si la persona marcó entrada o salida, se considera como presente (P).</li>
				<li>Si la persona no marcó entrada ni salida, y su último marcaje registrado en el sistema fue una entrada, se considera como presente (P).</li>
				<li>Si la persona no marcó entrada ni salida, y su último marcaje registrado en el sistema fue una salida, se considera como ausente (A).</li>
			</ul>
		</b-alert>
		<b-row>
			<b-col class="mb-2 pr-1" sm="auto">
				<b-form-select v-model="month" :options="months" @input="load()"></b-form-select>
			</b-col>
			<b-col class="mb-2 px-1" sm="auto">
				<!--
				<b-button variant="warning" @click="goTo('Reports')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Reportes">
					<i class="fas fa-arrow-left"></i> Volver a Reportes
				</b-button>
				-->
				<b-overlay
					:show="loading"
					rounded
					opacity="0.6"
					class="d-inline-block mr-2"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar tabla de asistencia">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<b-button @click="toggleSticky()" variant="light" v-b-tooltip.hover.bottomright title="Mantener nombres de columnas siempre visibles">
					<i class="fas fa-thumbtack"></i>
					<span> Inmovilizar</span>
				</b-button>
			</b-col>
			<b-col class="mb-2 px-1" sm="auto">
				<b-form-input v-model="filter" placeholder="Buscar" debounce="500" :formatter="cleanFilter"></b-form-input>
			</b-col>
			<b-col class="pl-1">
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda">
					<i class="fas fa-question"></i>
				</b-button>
			</b-col>
			<b-col class="text-right" sm="auto">
				<b-overlay
					:show="excel.loading"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="success"
					class="d-block"
				>
					<b-button
						variant="success"
						@click="exportExcel()"
						v-b-tooltip.hover.bottomright
						title="Descargar reporte a su equipo en formato Excel"
					>
						<i class="fas fa-file-excel"></i>
						<span> Exportar a Excel</span>
					</b-button>
				</b-overlay>
			</b-col>
		</b-row>
		<b-card no-body>
			<b-skeleton-wrapper :loading="loading">
				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="20"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<b-table
					:items="data.attendance"
					:fields="fields"
					:filter="filter"
					small
					hover
					responsive
					:sticky-header="sticky"
					bordered
					head-variant="light"
					class="text-size-085 mb-0"
				>
					<template #head()="data">
						<div class="text-center my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #cell()="data">
						<div class="text-center cursor-pointer py-1" :title="data.value ? 'Día ' + data.field.label + (data.value < 200 ? ', Presente' : ', Ausente') : 'Aún no hay datos'">
							<span v-if="data.value" class="text-white">
								<span v-if="data.value < 200">P</span>
								<span v-else>A</span>
							</span>
						</div>
					</template>
					<template #head(identifier)="data">
						<div class="my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #head(names)="data">
						<div class="my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #head(primary_last_name)="data">
						<div class="my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #head(second_last_name)="data">
						<div class="my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #head(shift)="data">
						<div class="my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #head(area)="data">
						<div class="my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #head(position)="data">
						<div class="my-1">
							<span>{{data.label}}</span>
						</div>
					</template>
					<template #cell(identifier)="data">
						<div class="text-center" style="white-space: pre !important;">
							<span class="text-uppercase">{{parseIdentifier(data.value)}}</span>
						</div>
					</template>
					<template #cell(names)="data">
						<div>
							<span v-if="data.value" class="text-uppercase">{{data.value}}</span>
							<span v-else>-</span>
						</div>
					</template>
					<template #cell(primary_last_name)="data">
						<div>
							<span v-if="data.value" class="text-uppercase">{{data.value}}</span>
							<span v-else>-</span>
						</div>
					</template>
					<template #cell(second_last_name)="data">
						<div>
							<span v-if="data.value" class="text-uppercase">{{data.value}}</span>
							<span v-else>-</span>
						</div>
					</template>
					<template #cell(shift)="data">
						<div>
							<span v-if="data.value" class="text-uppercase">{{data.value}}</span>
							<span v-else class="text-danger">
								<strong>SIN TURNO</strong>
							</span>
						</div>
					</template>
					<template #cell(area)="data">
						<div>
							<span v-if="data.value" class="text-uppercase">{{data.value}}</span>
							<span v-else class="text-danger">
								<strong>SIN ÁREA</strong>
							</span>
						</div>
					</template>
					<template #cell(position)="data">
						<div>
							<span v-if="data.value" class="text-uppercase">{{data.value}}</span>
							<span v-else class="text-danger">
								<strong>SIN CARGO</strong>
							</span>
						</div>
					</template>
				</b-table>

				<!-- OLD TABLE
				<table class="table table-bordered table-sm text-size-085">
					<tr>
						<th class="text-uppercase">Turno</th>
						<th class="text-uppercase">Nombre</th>
						<th class="text-uppercase">Área</th>
						<th class="text-uppercase">Cargo</th>
						<th class="text-uppercase">RUT</th>
						<th v-for="(category, index) in data.categories" :key="index" class="text-center">
							{{category}}
						</th>
					</tr>
					<tr v-for="(worker, worker_index) in data.attendance" :key="worker_index">
						<td class="text-center">
							<span v-if="worker.shift">{{worker.shift}}</span>
							<strong v-else class="text-danger">SIN TURNO</strong>
						</td>
						<td class="text-center text-uppercase">
							{{worker.primary_last_name}}
							{{worker.second_last_name}}
							{{worker.names}}
						</td>
						<td class="text-center text-uppercase">
							<span v-if="worker.area">{{worker.area}}</span>
							<strong v-else class="text-danger">SIN AREA</strong>
						</td>
						<td class="text-uppercase">
							<span v-if="worker.position">{{worker.position}}</span>
							<strong v-else class="text-danger">SIN CARGO</strong>
						</td>
						<td class="text-center">
							{{parseIdentifier(worker.identifier)}}
						</td>
						<td v-for="(code, code_index) in worker.data" :key="code_index" :class="code < 200 ? 'bg-new-primary' : 'bg-new-danger'">
							<div class="text-center text-white">
								<span v-if="code < 200">P</span>
								<span v-else>A</span>
							</div>
						</td>
					</tr>
				</table>
				-->

			</b-skeleton-wrapper>
		</b-card>

		<!-- HELP -->
		<b-modal id="attendance-help" title="Información de Ayuda" size="lg">

			<p class="text-size-13">Esta es una guía rápida para conocer las funciones del Reporte de Asistencia.</p>

			<h4>Opciones Básicas <small class="text-muted">(parte superior izquierda)</small></h4>

			<table class="table table-bordered">
				<tr>
					<td class="text-center bg-secondary" style="min-width: 150px;">
						<b-select :options="months" value="oct"></b-select>
					</td>
					<td>
						<p class="text-transform-none mb-0">Esta lista desplegable permite seleccionar el mes de asistencia que desea consultar. Al presionar y escoger un mes, la tabla de asistencia se actualizará automáticamente mostrando la asistencia del mes seleccionado.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center bg-secondary">
						<b-button variant="light">
							<i class="fas fa-sync"></i> Actualizar
						</b-button>
					</td>
					<td>
						<p class="text-transform-none mb-0">Este botón recarga la información de asistencia desde la base de datos del sistema. Tenga presente que el sistema actualiza automáticamente la base de datos cada 1 hora durante todo el día, partiendo a las 00:00, luego a las 01:00, 02:00, 03:00, y así sucesivamente hasta las 23:00.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center bg-secondary">
						<b-button variant="light">
							<i class="fas fa-thumbtack"></i> Inmovilizar
						</b-button>
					</td>
					<td>
						<p class="text-transform-none mb-0">Modifica visualmente la tabla de asistencia para que la primera fila, que muestra los nombres de cada columna, se mantenga siempre visible al desplazarse verticalmente. Si su pantalla es pequeña y no logra ver la tabla completa (faltan días al lado derecho), active esta opción.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center bg-secondary">
						<b-input placeholder="Buscar"></b-input>
					</td>
					<td>
						<p class="text-transform-none mb-0">Este campo de búsqueda le permite filtrar la tabla rápidamente. Al escribir en él, la tabla mostrará sólo las filas que coincidan con lo que escribió. Puede escribir el nombre de un turno, nombres o apellidos del trabajador, área o RUT.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center bg-secondary">
						<b-button variant="info">
							<i class="fas fa-question"></i>
						</b-button>
					</td>
					<td>
						<p class="text-transform-none mb-0">Muestra esta ventana de ayuda que está leyendo.</p>
					</td>
				</tr>
			</table>

			<h4 class="mt-4">Tabla de Asistencia</h4>
			<table class="table table-bordered">
				<tr>
					<td class="text-center bg-light text-muted">
						<strong>ABC</strong>
						<br>
						<i class="fas fa-sort"></i>
					</td>
					<td>
						<p class="text-transform-none mb-0">La primera fila muestra los nombres de cada columna. Puede presionar las flechas <i class="fas fa-sort"></i> de cada columna para ordenar la tabla alfabéticamente de manera ascendente (de la A a la Z) o descendente (de la Z a la A).</p>
					</td>
				</tr>
				<tr>
					<td class="text-center table-attendance-primary">
						<p class="text-white px-3 pt-2 text-size-2">P</p>
					</td>
					<td>
						<p class="text-size-11 mb-1">Indica que el trabajador se encuentra <strong class="text-primary">presente</strong> y considera 4 casos:</p>
						<ol class="mb-0">
							<li>El trabajador marcó entrada y salida (personal que no pernocta en faena).</li>
							<li>El trabajador marcó entrada pero no salida (personal que pernocta en faena al comenzar su turno).</li>
							<li>El trabajador no marcó entrada pero sí salida (personal que pernocta en faena al finalizar su turno).</li>
							<li>El trabajador no marcó entrada ni salida, pero su último control fue una entrada (personal que pernocta en faena y aún no finaliza su turno).</li>
						</ol>
					</td>
				</tr>
				<tr>
					<td class="text-center table-attendance-danger">
						<p class="text-white px-3 pt-2 text-size-2">A</p>
					</td>
					<td>
						<p class="text-size-11 mb-1">Indica que el trabajador se encuentra <strong class="text-danger">ausente</strong>.</p>
						<p>Esto sucede cuando trabajador no marca entrada ni salida, y su último control fue una salida, por lo tanto se asume que no está en faena.</p>
					</td>
				</tr>
			</table>

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="secondary">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /HELP -->

		<!-- CONTROLS EXPORT MODAL -->
		<b-modal id="excel-export-ready" hide-header size="md">
			<h4 class="mb-2">¡Su reporte de asistencia está listo!</h4>
			<p class="text-size-12 text-muted mb-3">Presione el botón verde para descargarlo a su equipo.</p>
			<b-button v-if="excel.uuid" :href="api_path+'reports/'+excel.uuid" variant="success" block size="lg">
				<i class="fas fa-file-excel mr-1"></i>
				<span>Descargar Reporte</span>
			</b-button>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
		<!-- /CONTROLS EXPORT MODAL -->

	</div>
</template>

<script>
export default {
	name: 'Attendance',
	components: {
	},
	data() {
		return {
			excel: {
				uuid: null,
				loading: false
			},
			loading: true,
			filter: '',
			month: 'feb',
			sticky: false,
			months: [
				{ value: 'ene', text: 'Enero 2024' },
				{ value: 'feb', text: 'Febrero 2024' },
				{ value: 'mar', text: 'Marzo 2024' },
				{ value: 'abr', text: 'Abril 2024' },
				{ value: 'may', text: 'Mayo 2024' },
				{ value: 'jun', text: 'Junio 2024' },
				{ value: 'jul', text: 'Julio 2024' },
				{ value: 'ago', text: 'Agosto 2024' },
				{ value: 'sep', text: 'Septiembre 2024' },
				{ value: 'oct', text: 'Octubre 2024' },
				{ value: 'nov', text: 'Noviembre 2024' },
				{ value: 'dic', text: 'Diciembre 2024' },
			],
			data: {
				attendance: [],
				latest: ''
			}
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
		state() {
			return this.$store.getters
		},
		fields() {
			if (this.data.attendance.length) {
				let fields = [
					{ key: 'shift', label: 'Turno', sortable: true },
					{ key: 'names', label: 'Nombres', sortable: true },
					{ key: 'primary_last_name', label: 'Ap. Paterno', sortable: true },
					{ key: 'second_last_name', label: 'Ap. Materno', sortable: true },
					{ key: 'area', label: 'Área', sortable: true },
					//{ key: 'position', label: 'Cargo', sortable: true },
					{ key: 'identifier', label: 'RUT', sortable: true },
				]
				var days = 0
				if (this.month == 'ene') { days = 31 }
				else if (this.month == 'feb') { days = 29 }
				else if (this.month == 'mar') { days = 31 }
				else if (this.month == 'abr') { days = 30 }
				else if (this.month == 'may') { days = 31 }
				else if (this.month == 'jun') { days = 30 }
				else if (this.month == 'jul') { days = 31 }
				else if (this.month == 'ago') { days = 31 }
				else if (this.month == 'sep') { days = 30 }
				else if (this.month == 'oct') { days = 31 }
				else if (this.month == 'nov') { days = 30 }
				else if (this.month == 'dic') { days = 31 }

				for (var i = 1; i <= days; i++) {
					var label = i < 10 ? '0' + i.toString() : i.toString()
					fields.push({ key: i.toString(), label: label, sortable: false })
				}
				return fields
			} else {
				return []
			}
		},
		api_path() {
			return this.$store.state.api_path
		}
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		load() {
			this.loading = true
			this.data.attendance = []
			this.data.latest = ''
			this.$api.get('attendance', {
				params: {
					month: this.month
				}
			})
			.then(response => {
				this.loading = false
				this.data = response.data
			})
			.catch(() => {
				this.loading = false
			})
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		parseIdentifier(i) {
			if (i) return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		toggleSticky() {
			this.sticky = !this.sticky
		},
		cleanFilter(value) {
			return value.replace(/[^a-zA-Z0-9ñÑ ]/g,'');
		},
		help() {
			this.$bvModal.show('attendance-help')
		},
		exportExcel() {
			this.excel.loading = true
			this.excel.uuid = null
			this.$api.get('excel/attendance/'+this.month)
			.then(response => {
				this.excel.loading = false
				this.excel.uuid = response.data.uuid
				this.$bvModal.show('excel-export-ready')
			})
			.catch(error => {
				this.excel.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		download() {
			this.$api.get('reports/'+this.uuid)
		},
	}
}
</script>

<style scoped>
</style>
