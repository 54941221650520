<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="view-pass"
			:title="title"
			size="lg"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">
				
				<div v-if="pass.item">
					<b-row class="mb-4" v-if="pass.item.status === 1 && getters.prv_passes_review">
						<b-col>
							<b-button variant="success" block @click="approve_modal()" class="py-3">
								<i class="fas fa-check mr-1"></i>
								<span>APROBAR</span>
							</b-button>
						</b-col>
						<b-col>
							<b-button variant="danger" block @click="reject_modal()" class="py-3">
								<i class="fas fa-times mr-1"></i>
								<span>RECHAZAR</span>
							</b-button>
						</b-col>
						<!--
						<b-col>
							<b-button variant="dark" block @click="nullify_modal()" class="py-3">
								<i class="fas fa-trash mr-1"></i>
								<span>ANULAR</span>
							</b-button>
						</b-col>
						-->
					</b-row>
					<b-card bg-variant="success" class="mb-4" v-if="pass.item.status === 2">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-check mr-2"></i>
							<strong>PASE APROBADO</strong>
						</p>
					</b-card>
					<b-card bg-variant="danger" class="mb-4" v-if="pass.item.status === 3">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-times mr-2"></i>
							<strong>PASE RECHAZADO</strong>
						</p>
					</b-card>
					<b-card bg-variant="dark" class="mb-4" v-if="pass.item.status === 0">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-trash mr-2"></i>
							<strong>PASE ANULADO</strong>
						</p>
					</b-card>
					<b-list-group class="mb-4">
						<b-list-group-item v-if="pass.item.status === 3 || pass.item.status === 0">
							<b-row>
								<b-col sm="3" class="text-danger">
									<strong v-if="pass.item.status === 3">Motivo de Rechazo</strong>
									<strong v-else-if="pass.item.status === 0">Motivo de Anulación</strong>
								</b-col>
								<b-col>
									{{pass.item.comment}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>N° de Pase</strong>
								</b-col>
								<b-col>
									{{pass.item.id}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item v-if="pass.item.status === 1">
							<b-row>
								<b-col sm="3">
									<strong>Estado de Solicitud</strong>
								</b-col>
								<b-col>
									<b-badge variant="warning">EN ESPERA DE REVISIÓN</b-badge>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Fecha de Visita</strong>
								</b-col>
								<b-col>
									{{pass.item.date_start}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Fecha de Término</strong>
								</b-col>
								<b-col>
									{{pass.item.date_end}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Motivo de Visita</strong>
								</b-col>
								<b-col>
									{{pass.item.motive}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>E-mail de Anfitrión</strong>
								</b-col>
								<b-col>
									<span class="text-megamuted mr-1"><i class="fas fa-envelope"></i></span>
									<span>{{pass.item.host_email}}</span>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>
										<span v-if="pass.item.on_behalf">Empresa Solicitante</span>
										<span v-else>Empresa</span>
									</strong>
								</b-col>
								<b-col>
									<span>
										<span>{{pass.item.contact_name}}</span>
										<span class="text-muted mx-2">&ndash;</span>
										<span class="text-muted mr-1">RUT</span>
										<span>{{parseIdentifier(pass.item.contact_identifier)}}</span>
									</span>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item v-if="pass.item.on_behalf">
							<b-row>
								<b-col sm="3">
									<strong>Empresa Visitante</strong>
								</b-col>
								<b-col>
									<span>
										<span>{{pass.item.on_behalf_name}}</span>
										<span class="text-muted mx-2">&ndash;</span>
										<span class="text-muted mr-1">RUT</span>
										<span>{{parseIdentifier(pass.item.on_behalf_identifier)}}</span>
									</span>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Contacto</strong>
								</b-col>
								<b-col>
									<span>
										<span class="text-megamuted mr-1"><i class="fas fa-phone-alt"></i></span>
										<span class="mr-3">{{pass.item.contact_phone}}</span>
										<span class="text-megamuted mr-1"><i class="fas fa-envelope"></i></span>
										<span>{{pass.item.contact_email}}</span>
									</span>
								</b-col>
							</b-row>
						</b-list-group-item>
					</b-list-group>
					<b-card no-body>
						<b-card-header>
										<strong class="mr-2">Nómina de Personal</strong>
										<span class="text-muted">({{pass.item.members.length}} persona<span v-if="pass.item.members.length >1">s</span>)</span>
						</b-card-header>
						<b-table
							:items="pass.item.members"
							:fields="fields"
							hover
						>
							<template v-slot:cell(identifier)="data">
								<div>
									{{parseIdentifier(data.value)}}
								</div>
							</template>
						</b-table>
					</b-card>
				</div>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

		<!-- APPROVE MODAL -->
		<b-modal id="approve-pass" title="Aprobar Pase de Visita">
			<b-alert show variant="success" class="text-size-11 mb-0">
				<p class="mb-1">Al aprobar un Pase de Visita, se notificará a:</p>
				<ul class="mb-1">
					<li>Destinatario N°1</li>
					<li>Destinatario N°2</li>
					<li>Destinatario N°3</li>
					<li>Destinatario N°4</li>
					<li>Destinatario N°5</li>
					<li>Destinatario ...</li>
				</ul>
			</b-alert>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2">Cancelar</b-button>
				<b-button @click="approve()" variant="success">
					<i class="fas fa-check mr-1"></i>
					<span>Confirmar Aprobación</span>
				</b-button>
			</template>
		</b-modal>
		<!-- APPROVE MODAL -->

		<!-- REJECTION MODAL -->
		<b-modal id="reject-pass" title="Rechazar Pase de Visita">
			<b-alert show variant="danger" class="text-size-11">Al rechazar un Pase de Visita, se notificará al solicitante y al anfitrión el motivo de rechazo que escriba a continuación.</b-alert>
			<b-form-group label="Motivo de Rechazo">
				<b-input v-model="comment" :state="val_comment"></b-input>
				<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
				<b-form-invalid-feedback>Debe ingresar un motivo para poder rechazar el pase</b-form-invalid-feedback>
			</b-form-group>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2">Cancelar</b-button>
				<b-button @click="reject()" variant="danger" :disabled="!val_comment">
					<i class="fas fa-times mr-1"></i>
					<span>Confirmar Rechazo</span>
				</b-button>
			</template>
		</b-modal>
		<!-- REJECTION MODAL -->

		<!-- NULLIFY MODAL -->
		<b-modal id="nullify-pass" title="Anular Pase de Visita">
			<b-alert show variant="dark" class="text-size-11">Al anular un Pase de Visita, no se notificará al solicitante ni al anfitrión.</b-alert>
			<b-form-group label="Motivo de Anulación">
				<b-input v-model="comment" :state="val_comment"></b-input>
				<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
				<b-form-invalid-feedback>Debe ingresar un motivo para poder rechazar el pase</b-form-invalid-feedback>
			</b-form-group>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2">Cancelar</b-button>
				<b-button @click="nullify()" variant="dark" :disabled="!val_comment">
					<i class="fas fa-trash mr-1"></i>
					<span>Confirmar Anulación</span>
				</b-button>
			</template>
		</b-modal>
		<!-- NULLIFY MODAL -->

	</div>
</template>

<script>
export default {
	name: 'ViewPass',
	components: {
	},
	data() {
		return {
			loading: false,
			comment: '',
			fields: [
				{ key: 'identifier', label: 'RUT', sortable: true },
				{ key: 'names', label: 'Nombres', sortable: true },
				{ key: 'first_lastname', label: '1er Apellido', sortable: true },
				{ key: 'second_lastname', label: '2do Apellido', sortable: true }
			]
		}
	},
	props: {
		pass: Object
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		title() {
			if (this.pass.item) {
				return 'Pase de Visita N°' + this.pass.item.id
			} else {
				return 'Pase de Visita'
			}
		},
		passes() {
			return this.$store.state.passes
		},
		val_comment() {
			return this.comment.length > 0 ? true : false
		}
	},
	created() {
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'view-pass' || (!this.loading && (modalId == 'reject-pass' || modalId == 'nullify-pass'))) {
				this.comment = ''
			}
		})
	},
	methods: {
		reset() {
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		approve_modal() {
			this.$bvModal.show('approve-pass')
		},
		approve() {
			this.loading = true
			this.$bvModal.hide('approve-pass')
			this.$api.put('passes/'+this.pass.item.id, { action: 'approve' })
			.then(response => {
				this.loading = false
				let index = this.passes.data.data.findIndex(pass => pass.id === this.pass.item.id)
				this.passes.data.data[index].status = 2
				this.toast(true, 'Se aprobó el Pase de Visita N° '+response.data.id+' correctamente.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reject_modal() {
			this.$bvModal.show('reject-pass')
		},
		reject() {
			this.loading = true
			this.$bvModal.hide('reject-pass')
			this.$api.put('passes/'+this.pass.item.id, { action: 'reject', comment: this.comment })
			.then(response => {
				this.loading = false
				let index = this.passes.data.data.findIndex(pass => pass.id === this.pass.item.id)
				this.passes.data.data[index].status = 3
				this.passes.data.data[index].comment = this.comment
				this.toast(true, 'Se rechazó el Pase de Visita N° '+response.data.id+' correctamente.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		nullify_modal() {
			this.$bvModal.show('nullify-pass')
		},
		nullify() {
			this.loading = true
			this.$bvModal.hide('nullify-pass')
			this.$api.put('passes/'+this.pass.item.id, { action: 'nullify', comment: this.comment })
			.then(response => {
				this.loading = false
				let index = this.passes.data.data.findIndex(pass => pass.id === this.pass.item.id)
				this.passes.data.data[index].status = 0
				this.passes.data.data[index].comment = this.comment
				this.toast(true, 'Se anuló el Pase de Visita N° '+response.data.id+' correctamente.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		}
	}
}
</script>

<style scoped>
</style>
