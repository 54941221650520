<template>
	<div>
		<CreatePass></CreatePass>
		<CreateCargoPass></CreateCargoPass>
		<div class="login-box">
			<b-overlay :show="loading" rounded="sm">
				<b-card no-body>
					<b-card-body>
						<div class="text-center mb-4">
							<!--
							<img src="/img/header-logo.png" class="mb-3" />
							-->
							<img src="@/assets/logo-angamos.png" style="height: 90px;" class="mb-3" />
							<h4 class="mb-0">Control de Visitas</h4>
						</div>
						<b-button variant="primary py-4 btn-lg" block @click="createPass()">
							<span>Solicitar Pase</span>
							<i class="fas fa-arrow-right ml-2"></i>
						</b-button>
						<hr class="mx-1 my-4">
						<p class="text-uppercase text-center text-muted mb-3">
							<strong>Usuarios Registrados</strong>
						</p>
						<b-form action="#" @submit.prevent="login">
							<b-form-group label="Ingrese RUT">
								<!--
								<the-mask
									:class="class_username"
									:state="val_username"
									v-model="username"
									:mask="['##.###.###-K', '#.###.###-K']" 
									:tokens="mask_tokens"
								></the-mask>
								-->
								<b-input v-model="credentials.username" size="lg" :state="val_username" :formatter="cleanIdentifier" :maxlength="9"></b-input>
								<b-form-invalid-feedback>Por favor ingrese un RUT válido.</b-form-invalid-feedback>
								<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
							</b-form-group>
							<b-form-group label="Ingrese Contraseña">
								<b-input type="password" v-model="credentials.password" size="lg" :state="val_password"></b-input>
								<b-form-invalid-feedback>Por favor ingrese su contraseña.</b-form-invalid-feedback>
								<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
							</b-form-group>
							<b-button type="submit" variant="secondary" size="lg" block :disabled="!val_username||!val_password">
								<span>Ingresar</span>
							</b-button>
						</b-form>
					</b-card-body>
				</b-card>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->
			</b-overlay>

		</div>
	</div>
</template>

<script>
import CreatePass from '@/components/CreatePass.vue'
import CreateCargoPass from '@/components/CreateCargoPass.vue'
export default {
	name: 'Login',
	components: {
		CreatePass,
		CreateCargoPass,
	},
	data() {
		return {
			loading: false,
			credentials: {
				username: null,
				password: null
			},
			mask_tokens: {
				'#': {
					pattern: /\d/
				},
				K: {
					pattern: /[0-9kK]/,
					transform: v => v.toLocaleUpperCase()
				},
				'L': {
					pattern: /[a-zA-Z]/
				}
			}
		}
	},
	mounted() {
	},
	computed: {
		class_username() {
			if (this.credentials.username == null) return 'form-control form-control-lg'
			else if (this.credentials.username != null) {
				if (this.val_username) {
					return 'is-valid form-control form-control-lg'
				} else{
					return 'is-invalid form-control form-control-lg'
				}
			} else {
				return 'form-control form-control-lg'
			}
		},
		val_username() {
			if (this.credentials.username == null) return null
			if (this.credentials.username.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.credentials.username)) return false
			var dv = this.credentials.username.slice(-1)
			var rut = this.credentials.username.length == 9 ? this.credentials.username.slice(0,8) : this.credentials.username.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
		val_password() {
			return this.credentials.password == null ? null : this.credentials.password.length > 0 ? true : false
		}
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay,
				toaster: 'b-toaster-top-center'
			})
		},
		login() {
			this.loading = true
			this.$store.dispatch('login', this.credentials)
			.then(() => {
				this.loading = false
				this.$router.push({ name: 'Home' })
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 5000)
			})
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
		createPass() {
			this.$bvModal.show('create-pass')
		},
		createCargoPass() {
			this.$bvModal.show('create-cargo-pass')
		},
	}
}
</script>

<style scoped>
.login-box {
	width: 320px;
	margin: 50px auto 0px;
}
</style>
