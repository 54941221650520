<template>
	<div>
		<b-navbar id="navigation" toggleable="lg" type="dark" variant="dark" class="mb-3">
			<b-navbar-brand>
				<img src="@/assets/favicon.png" class="logo cursor-pointer" @click="isRoute('Home') ? false : home()" v-b-tooltip.hover.bottomright title="Volver al inicio" />
				<span class="text-white">{{this.$route.meta.title}}</span>
			</b-navbar-brand>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav class="ml-auto">

					<b-nav-item v-if="loading">
						<b-spinner small type="grow"></b-spinner>
					</b-nav-item>

					<router-link to="/empresa" custom v-slot="{ navigate }" v-if="!getters.internal && !getters.session_loading">
						<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
							<i class="fas fa-building mr-2"></i>
							<span>Empresa</span>
						</b-nav-item>
					</router-link>

					<b-nav-item @click="$bvModal.show('pass-selector')" v-if="getters.prv_passes || getters.prv_cargo_passes || getters.prv_corporate_passes">
						<i class="fas fa-check mr-2"></i>
						<span>Pases</span>
					</b-nav-item>

					<router-link to="/reportes" custom v-slot="{ navigate }" v-if="getters.prv_reports">
						<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
							<i class="fas fa-chart-pie mr-2"></i>
							<span>Reportes</span>
						</b-nav-item>
					</router-link>

					<router-link to="/sistema" custom v-slot="{ navigate }" v-if="getters.prv_system">
						<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
							<i class="fas fa-cog mr-2"></i>
							<span>Sistema</span>
						</b-nav-item>
					</router-link>

					<router-link to="/cuenta" custom v-slot="{ navigate }">
						<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
							<i class="fas fa-lock mr-2"></i>
							<span>Cuenta</span>
						</b-nav-item>
					</router-link>

					<router-link to="/salir" custom v-slot="{ navigate }">
						<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
							<i class="fas fa-sign-out-alt mr-2"></i>
							<span>Salir</span>
						</b-nav-item>
					</router-link>

				</b-navbar-nav>
			</b-collapse>
		</b-navbar>

		<!-- COMPANY SELECTOR -->
		<b-modal id="company-selector" title="Escoja Tipo de Empresas" size="sm" hide-footer body-class="p-4">
			<b-button
				:variant="isRouteVariant('Contractors')"
				size="lg"
				@click="company_type(1)"
				block
				:disabled="isRoute('Contractors')"
				v-if="getters.prv_contractors"
			>Contratistas</b-button>
			<b-button
				:variant="isRouteVariant('Subcontractors')"
				size="lg"
				class="mt-3"
				@click="company_type(2)"
				block
				:disabled="isRoute('Subcontractors')"
				v-if="getters.prv_subcontractors"
			>Subcontratistas</b-button>
		</b-modal>
		<!-- /COMPANY SELECTOR -->

		<!-- SERVICE SELECTOR -->
		<b-modal id="service-selector" title="Escoja Tipo de Servicios" size="sm" hide-footer body-class="p-4">
			<b-button
				:variant="isRouteVariant('ServiceOrders')"
				size="lg"
				@click="service_type(1)"
				block
				:disabled="isRoute('ServiceOrders')"
				v-if="getters.prv_service_orders"
			>Órdenes de Servicio</b-button>
			<b-button
				:variant="isRouteVariant('Contracts')"
				size="lg"
				class="mt-3"
				@click="service_type(2)"
				block
				:disabled="isRoute('Contracts')"
				v-if="getters.prv_contracts"
			>Contratos</b-button>
			<b-button
				:variant="isRouteVariant('Warranties')"
				size="lg"
				class="mt-3"
				@click="service_type(3)"
				block
				:disabled="isRoute('Warranties')"
				v-if="getters.prv_warranties"
			>Garantías</b-button>
			<b-button
				:variant="isRouteVariant('Subcontracts')"
				size="lg"
				class="mt-3"
				@click="service_type(4)"
				block
				:disabled="isRoute('Subcontracts')"
				v-if="getters.prv_subcontracts"
			>Subcontratos</b-button>
		</b-modal>
		<!-- /SERVICE SELECTOR -->

		<!-- WORKER SELECTOR -->
		<b-modal id="worker-selector" title="Escoja Tipo de Trabajadores" size="sm" hide-footer body-class="p-4">
			<b-button
				:variant="isRouteVariant('InternalWorkers')"
				size="lg"
				@click="worker_type(1)"
				block
				:disabled="isRoute('InternalWorkers')"
				v-if="getters.prv_internal_workers"
			>HMC</b-button>
			<b-button
				:variant="isRouteVariant('ContractorWorkers')"
				size="lg"
				class="mt-3"
				@click="worker_type(2)"
				block
				:disabled="isRoute('ContractorWorkers')"
				v-if="getters.prv_contractor_workers"
			>Contratistas</b-button>
			<b-button
				:variant="isRouteVariant('SubcontractorWorkers')"
				size="lg"
				class="mt-3"
				@click="worker_type(3)"
				block
				:disabled="isRoute('SubcontractorWorkers')"
				v-if="getters.prv_subcontractor_workers"
			>Subcontratistas</b-button>
		</b-modal>
		<!-- /WORKER SELECTOR -->

		<!-- VEHICLE SELECTOR -->
		<b-modal id="vehicle-selector" title="Escoja Tipo de Vehículos" size="sm" hide-footer body-class="p-4">
			<b-button
				:variant="isRouteVariant('InternalVehicles')"
				size="lg"
				@click="vehicle_type(1)"
				block
				:disabled="isRoute('InternalVehicles')"
				v-if="getters.prv_internal_vehicles"
			>HMC</b-button>
			<b-button
				:variant="isRouteVariant('ContractorVehicles')"
				size="lg"
				class="mt-3"
				@click="vehicle_type(2)"
				block
				:disabled="isRoute('ContractorVehicles')"
				v-if="getters.prv_contractor_vehicles"
			>Contratistas</b-button>
			<b-button
				:variant="isRouteVariant('SubcontractorVehicles')"
				size="lg"
				class="mt-3"
				@click="vehicle_type(3)"
				block
				:disabled="isRoute('SubcontractorVehicles')"
				v-if="getters.prv_subcontractor_vehicles"
			>Subcontratistas</b-button>
		</b-modal>
		<!-- /VEHICLE SELECTOR -->

		<!-- PASS SELECTOR -->
		<b-modal id="pass-selector" title="Escoja Tipo de Pase" size="sm" hide-footer body-class="p-4">
			<b-button
				:variant="isRouteVariant('Passes')"
				size="lg"
				@click="pass_type(1)"
				block
				
				v-if="getters.prv_passes"
			>Pases para Visitas</b-button>
			<b-button
				:variant="isRouteVariant('CargoPasses')"
				size="lg"
				class="mt-3"
				@click="pass_type(2)"
				block
				disabled
				v-if="getters.prv_cargo_passes"
			>Pases para Transportistas</b-button>
			<b-button
				:variant="isRouteVariant('CorporatePasses')"
				size="lg"
				class="mt-3"
				@click="pass_type(3)"
				block
				disabled
				v-if="getters.prv_corporate_passes"
			>Pases para Autoridades</b-button>
		</b-modal>
		<!-- /PASS SELECTOR -->

	</div>
</template>

<script>
export default {
	name: 'Navigation',
	computed: {
		getters() {
			return this.$store.getters
		},
		session() {
			return this.$store.getters.session
		},
		loading() {
			return this.$store.state.user.data.privileges.loading
		}
	},
	methods: {
		isRoute(name) {
			return this.$route.name === name ? true : false
		},
		isRouteVariant(name) {
			return this.$route.name === name ? 'primary' : 'primary'
		},
		home() {
			this.$router.push({ name: 'Home' })
		},
		company_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'Contractors' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'Subcontractors' })
			}
			this.$bvModal.hide('company-selector')
			this.$store.state.subroutes.companies = type
		},
		service_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'ServiceOrders' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'Contracts' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'Warranties' })
			}
			else if (type === 4) {
				this.$router.push({ name: 'Subcontracts' })
			}
			this.$bvModal.hide('service-selector')
			this.$store.state.subroutes.services = type
		},
		worker_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'InternalWorkers' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'ContractorWorkers' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'SubcontractorWorkers' })
			}
			this.$bvModal.hide('worker-selector')
			this.$store.state.subroutes.workers = type
		},
		vehicle_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'InternalVehicles' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'ContractorVehicles' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'SubcontractorVehicles' })
			}
			this.$bvModal.hide('vehicle-selector')
			this.$store.state.subroutes.vehicles = type
		},
		pass_type(type) {
			if (type === 1) {
				this.$router.push({ name: 'Passes' })
			}
			else if (type === 2) {
				this.$router.push({ name: 'CargoPasses' })
			}
			else if (type === 3) {
				this.$router.push({ name: 'CorporatePasses' })
			}
			this.$bvModal.hide('pass-selector')
		}
	}
}
</script>