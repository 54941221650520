<template>
	<div>
		<b-card no-body>
			<b-card-header>
				<b-row>
					<b-col class="card-title-middle">
						<strong>CONTROLES DE INGRESO Y SALIDA</strong>
					</b-col>
					<b-col class="text-right">
						<b-button to="/reportes" variant="outline-primary" size="sm" class="mr-2" v-b-tooltip.hover title="Volver a Reportes">
							<span class="fas fa-arrow-left"></span>
						</b-button>
						<b-button @click="refresh()" variant="outline-primary" size="sm" v-b-tooltip.hover title="Actualizar la información">
							<span class="fas fa-sync"></span>
						</b-button>
					</b-col>
				</b-row>
			</b-card-header>
			<b-card-body class="p-3">
				<b-card class="mb-4">
					<b-card-body class="p-0">
						<p class="inner-card-title">INGRESOS Y SALIDAS</p>
						<b-overlay :show="summary.loading">
							<apexchart :options="summary.chart.options" :series="summary.chart.series" height="400"></apexchart>
						</b-overlay>
					</b-card-body>
				</b-card>
				<b-card class="mb-4">
					<b-card-body class="p-0">
						<p class="inner-card-title">Ingresos: Aprobados & Rechazados</p>
						<b-overlay :show="success.loading">
							<apexchart :options="success.chart.options" :series="success.chart.series" height="400"></apexchart>
						</b-overlay>
					</b-card-body>
				</b-card>
				<b-card>
					<b-card-body class="p-0">
						<p class="inner-card-title">Ingresos: Motivos de Rechazo</p>
						<b-overlay :show="motives.loading">
							<apexchart :options="motives.chart.options" :series="motives.chart.series" height="400"></apexchart>
						</b-overlay>
					</b-card-body>
				</b-card>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'StatsControl',
	data() {
		return {
			summary: {
				loading: true,
				chart: {
					series: [],
					options: {
						stroke: {
							width: 2
						},
						chart: {
							type: 'area',
							stacked: true,
							toolbar: {
								show: false
							}
						},
						dataLabels: {
							enabled: true,
						},
						colors: [ '#008FFB', '#AAAAAA' ],
						fill: {
							opacity: 1
						}
					}
				}
			},
			success: {
				loading: true,
				chart: {
					series: [],
					options: {
						stroke: {
							width: 2
						},
						chart: {
							type: 'area',
							stacked: true,
							toolbar: {
								show: false
							}
						},
						dataLabels: {
							enabled: true,
						},
						colors: [ '#00E396', '#FF4560' ],
						fill: {
							opacity: 1
						}
					}
				}
			},
			motives: {
				loading: true,
				chart: {
					series: [],
					options: {
						stroke: {
							width: 2
						},
						chart: {
							type: 'area',
							stacked: true,
							toolbar: {
								show: false
							}
						},
						dataLabels: {
							enabled: true,
						},
						//colors: [ '#00E396', '#FF4560' ],
						fill: {
							opacity: 1
						}
					}
				}
			}
		}
	},
	created() {
		this.getSummary()
		this.getSuccess()
		this.getMotives()
	},
	methods: {
		refresh() {
			this.reset()
			this.getSummary()
			this.getSuccess()
			this.getMotives()
		},
		reset() {
			this.summary.chart.series = []
			this.success.chart.series = []
			this.motives.chart.series = []
		},
		getSummary() {
			this.summary.loading = true
			this.$api.post('stats/control/summary')
			.then(response => {
				this.summary.loading = false
				this.summary.chart.series = response.data.series
			})
		},
		getSuccess() {
			this.success.loading = true
			this.$api.post('stats/control/success')
			.then(response => {
				this.success.loading = false
				this.success.chart.series = response.data.series
			})
		},
		getMotives() {
			this.motives.loading = true
			this.$api.post('stats/control/motives')
			.then(response => {
				this.motives.loading = false
				this.motives.chart.series = response.data.series
			})
		}
	},
}
</script>

<style scoped>
.card-title-middle {
	padding-top: 3px;
}
.inner-card-title {
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.15em;
	margin: 0;
}
.inner-card-legend {
	margin: 0;
}
.legend-primary {
	color: #008FFB;
}
.legend-success {
	color: #00E396;
}
.legend-danger {
	color: #FF4560;
}
.legend-warning {
	color: #FEB019;
}
.legend-secondary {
	color: #AAAAAA;
}
</style>