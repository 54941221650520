<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="update-worker"
			:title="'Actualizar ' + type_name"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading || updating" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="6">
						<b-form-group label="RUT">
							<b-input
								v-model="worker.identifier"
								:state="val_identifier"
								:formatter="cleanIdentifier"
								:maxlength="9"
								disabled
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Nombres">
							<b-input
								v-model="worker.names"
								:state="val_names"
								:disabled="worker.terminated"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Apellido Paterno">
							<b-input
								v-model="worker.primary_last_name"
								:state="val_primary_last_name"
								:disabled="worker.terminated"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Apellido Materno">
							<b-input
								v-model="worker.second_last_name"
								:state="val_second_last_name"
								:disabled="worker.terminated"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Área o Departamento">
							<b-input
								v-model="worker.area"
								:state="val_area"
								:disabled="worker.terminated"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Cargo">
							<b-input
								v-model="worker.position"
								:state="val_position"
								:disabled="worker.terminated"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Tipo de Contrato">
							<b-select
								v-model="worker.contract_type"
								:options="contract_type_options"
								:state="val_contract_type"
								@input="changeContractType()"
								:disabled="worker.terminated"
							></b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group :label="type == 1 && worker.contract_type == 3 ? 'Inicio de Práctica' : 'Inicio de Contrato'">
							<b-form-datepicker
								:disabled="worker.terminated"
								v-model="worker.contract_start"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								:state="val_contract_start"
								@input="worker.contract_end = ''"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="worker.contract_type == 1 || worker.contract_type == 3">
						<b-form-group :label="type == 1 && worker.contract_type == 3 ? 'Término de Práctica' : 'Término de Contrato'">
							<b-form-datepicker
								:disabled="worker.terminated"
								v-model="worker.contract_end"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								:state="val_contract_end"
								:min="worker.contract_start"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="type == 1 && worker.contract_type != 3">
						<b-form-group label="Estado">
							<b-select
								v-model="worker.terminated"
								:options="terminated_options"
								@input="changeTermination()"
								:state="val_terminated"
							></b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="worker.terminated && type == 1 && worker.contract_type != 3">
						<b-form-group label="Fecha de Finiquito">
							<b-form-datepicker
								v-model="worker.termination_date"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								placeholder="Seleccione una fecha"
								:state="val_termination_date"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<!--<b-col sm="12" v-if="type != 1 && worker.req_done >= 9">-->
					<b-col sm="12" v-if="type != 1">
						<b-form-group label="¿Habilitar como conductor?">
							<b-form-checkbox-group v-model="worker.driver" size="lg">
								<b-form-checkbox :value="false" inline>
									<span>No</span>
								</b-form-checkbox>
								<b-form-checkbox :value="true" inline>
									<span v-if="!worker.driver">Sí</span>
									<span v-else class="text-primary">
										<strong>Sí</strong>
									</span>
								</b-form-checkbox>
							</b-form-checkbox-group>
						</b-form-group>
					</b-col>
					

					<!-- COPPER MARK -->
					<!-- 11/09/2023  -->
					<b-col sm="6">
						<b-form-group label="Fecha de Nacimiento">
							<b-form-datepicker
								v-model="worker.birth_date"
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
								:state="val_birth_date"
								max="2005-12-31"
								label-no-date-selected="Use las flechas para cambiar mes o año"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback :state="val_birth_date">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_birth_date">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Nacionalidad">
							<b-overlay :show="countries.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select
									v-model="worker.country_id"
									:state="val_country"
									:disabled="countries.loading"
									v-if="!countries.data.data"
									@change="worker.migration_status_id = null"
								>
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Nacionalidad...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in countries.data" :key="index" :value="item.id">{{ item.nationality.toUpperCase() }}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback :state="val_country">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_country">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="worker.country_id != null && worker.country_id != 1">
						<b-form-group label="Estado Migratorio" :disabled="worker.country_id == null || worker.country_id === 1">
							<b-overlay :show="migration_statuses.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="worker.migration_status_id" :state="val_migration_status" :disabled="migration_statuses.loading" v-if="!migration_statuses.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Estado...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in migration_statuses.data" :key="index" :value="item.id">{{ item.name.toUpperCase() }}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback :state="val_migration_status">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_migration_status">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Persona con Discapacidad">
							<b-form-radio-group v-model="worker.has_disabilities" size="lg">
								<b-form-radio :value="true" inline :state="val_disabilities">
									<span>Sí</span>
								</b-form-radio>
								<b-form-radio :value="false" inline :state="val_disabilities">
									<span>No</span>
								</b-form-radio>
							</b-form-radio-group>
							<b-form-invalid-feedback :state="val_disabilities">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_disabilities">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_missing_termination">El contrato de este trabajador ha terminado, por favor cambie el Estado a FINIQUITADO o actualice la información de contrato.</span>
					<span v-else-if="val_full">¡Todo bien! Para terminar, presione el botón ACTUALIZAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading || updating">Cancelar</b-button>
				<b-button @click="update()" variant="success" :disabled="!val_full || loading || updating">ACTUALIZAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'UpdateWorker',
	components: {
	},
	data() {
		return {
			updating: false,
			contract_type_options: [],
			terminated_options: [
				{ value: false, text: 'VIGENTE' },
				{ value: true, text: 'FINIQUITADO' }
			]
		}
	},
	props: {
		type: Number
	},
	watch: {
		type(worker_type) {
			this.updateContractTypes(worker_type)
		}
	},
	computed: {
		worker() {
			return this.$store.state.worker.data
		},
		loading() {
			return this.$store.state.worker.loading
		},
		migration_statuses() {
			return this.$store.state.migration_statuses
		},
		countries() {
			return this.$store.state.countries
		},
		val_full() {
			return this.val_identifier &&
					this.val_names &&
					this.val_primary_last_name &&
					this.val_second_last_name &&
					this.val_area &&
					this.val_position &&
					this.val_contract_type &&
					this.val_contract_start &&
					this.val_contract_end &&
					this.val_birth_date &&
					this.val_country &&
					(this.worker.country_id == 1 || (this.worker.country_id != 1 && this.val_migration_status)) &&
					this.val_disabilities &&
					(this.type > 1 || (this.type == 1 && ((!this.val_missing_termination && !this.worker.terminated) || (this.worker.terminated && this.val_termination_date)))) ? true : false
		},
		val_names() {
			return this.worker.names && this.worker.names.length > 0 ? true : false
		},
		val_primary_last_name() {
			return this.worker.primary_last_name && this.worker.primary_last_name.length > 0 ? true : false
		},
		val_second_last_name() {
			return this.worker.second_last_name && this.worker.second_last_name.length > 0 ? true : false
		},
		val_area() {
			return this.worker.area && this.worker.area.length > 0 ? true : false
		},
		val_position() {
			return this.worker.position && this.worker.position.length > 0 ? true : false
		},
		val_contract_type() {
			return this.worker.contract_type != null ? true : false
		},
		val_contract_start() {
			return this.worker.contract_start && this.worker.contract_start.length > 0 ? true : false
		},
		val_contract_end() {
			return (this.worker.contract_end && this.worker.contract_end.length > 0 || this.worker.contract_type === 2) ? true : false
		},
		val_identifier() {
			if (this.worker.identifier.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.worker.identifier)) return false
			var dv = this.worker.identifier.slice(-1)
			var rut = this.worker.identifier.length == 9 ? this.worker.identifier.slice(0,8) : this.worker.identifier.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
		val_terminated() {
			return this.worker.terminated != null ? true : false
		},
		val_termination_date() {
			return this.worker.termination_date && this.worker.termination_date.length > 0 ? true : false
		},
		val_birth_date() {
			return this.worker.birth_date && this.worker.birth_date.length > 0 ? true : false
		},
		val_country() {
			return this.worker.country_id != null ? true : false
		},
		val_migration_status() {
			return this.worker.migration_status_id != null ? true : false
		},
		val_disabilities() {
			return this.worker.has_disabilities != null ? true : false
		},
		type_name() {
			if (this.type) {
				switch (this.type) {
					case 1:
						return 'Trabajador HMC'
					case 2:
						return 'Trabajador Contratista'
					case 3:
						return 'Trabajador Subcontratista'
					default:
						return 'Trabajador'
				}
			} else {
				return 'Trabajador'
			}
		},
		val_missing_termination() {
			if (this.type == 1 && this.worker.contract_type == 1 && this.worker.contract_end) {
				var contract_end = new Date(this.worker.contract_end+'T00:00:00')
				var today = new Date()
				today.setHours(0,0,0,0)
				if (contract_end < today && this.worker.terminated == false) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-worker') { this.reset() }
		})
		this.updateContractTypes(this.type)
	},
	methods: {
		reset() {
			this.$store.commit('reset_worker')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		update() {
			this.updating = true
			this.worker.type = this.type
			this.$api.put('workers/'+this.worker.id, this.worker)
			.then(response => {
				this.updating = false
				this.$emit('update')
				this.$bvModal.hide('update-worker')
				this.toast(true, 'Se guardaron los cambios del trabajador "'+response.data.person.names+' '+response.data.person.primary_last_name+' '+response.data.person.second_last_name+'" correctamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
		changeContractType() {
			if (this.worker.contract_type === 2) {
				this.worker.contract_end = ''
			}
		},
		changeTermination() {
			if (this.worker.terminated == false) {
				this.worker.termination_date = ''
			}
		},
		updateContractTypes(worker_type) {
			if (worker_type == 1) {
				this.contract_type_options = [
					{ value: 1, text: 'CONTRATO FIJO' },
					{ value: 2, text: 'CONTRATO INDEFINIDO' },
					{ value: 3, text: 'ALUMNO EN PRÁCTICA' },
				]
			} else {
				this.contract_type_options = [
					{ value: 1, text: 'CONTRATO FIJO' },
					{ value: 2, text: 'CONTRATO INDEFINIDO' },
				]
			}
		}
	}
}
</script>

<style scoped>
</style>
