<template>
	<div>
		<b-row>
			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('Reports')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Reportes">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->
		</b-row>
		<ViewDateExpirations></ViewDateExpirations>
		<b-overlay :show="loading">
			<v-calendar
				:masks="masks"
				:attributes="expirations"
				disable-page-swipe
				is-expanded
			>
				<template v-slot:day-content="{ day, attributes }">
					<div :id="day.id">
						<span class="text-secondary">{{ day.day }}</span>
						<div @click="openDateModal(day.id)" style="cursor:pointer;font-size:0.9em">
							<p
								v-for="attr in attributes"
								:key="attr.key"
								class="rounded-sm text-center"
								:class="attr.customData.class"
								style="padding: 2px 4px"
							>
								{{ attr.customData.count }} <span v-if="attr.customData.count > 1">documentos</span><span v-else>documento</span>
							</p>
						</div>
					</div>
				</template>
			</v-calendar>
		</b-overlay>
	</div>
</template>

<script>
import ViewDateExpirations from '@/components/ViewDateExpirations'
export default {
	name: 'Expirations',
	components: {
		ViewDateExpirations
	},
	data() {
		return {
			expirations: [],
			loading: true,
			year: 2024,
			years: [
				{ value: 2019, text: '2019' },
				{ value: 2020, text: '2020' },
				{ value: 2021, text: '2021' },
				{ value: 2022, text: '2022' },
				{ value: 2023, text: '2023' },
				{ value: 2024, text: '2024' },
				{ value: 2025, text: '2025' },
				{ value: 2026, text: '2026' },
				{ value: 2027, text: '2027' },
				{ value: 2028, text: '2028' },
				{ value: 2029, text: '2029' },
				{ value: 2030, text: '2030' }
			],
			masks: {
				weekdays: 'WWW',
			},
		}
	},
	mounted() {
		this.getData()
	},
	computed: {
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		getData() {
			this.loading = true
			this.$api.post('expirations', { year: this.year })
			.then(response => {
				this.loading = false
				if (response.data.success) {
					this.expirations = response.data.object
				}
			})
		},
		openDateModal(date) {
			this.$bvModal.show('view-date-expirations')
			this.$store.dispatch('get_date_expirations', date)
		}
	}
}
</script>

<style>
.vc-container {
	border: none !important;
}
.vc-day {
	padding: 0 5px 3px 5px;
	height: 70px;
	border: 1px solid #ddd;
	border-right: none;
	border-bottom: none;
	background-color: #f8fafc;
}
.vc-day.on-right {
	border-right: 1px solid #ddd;
}
.vc-day.on-bottom {
	border-bottom: 1px solid #ddd;
}
.weekday-7, .weekday-1 {
	background-color: #eff8ff;
}
</style>
