<template>
	<div>
		<div class="login-box">
			<div class="text-center mb-3">
				<router-link to="/ingresar" custom v-slot="{ navigate }">
					<a href="#" @click="navigate" @keypress.enter="navigate" role="link" class="text-muted">
						<i class="fas fa-arrow-left mr-2"></i>
						<span>Regresar al Inicio</span>
					</a>
				</router-link>
			</div>
			<b-overlay :show="loading" rounded="sm">
				<b-card no-body>
					<b-card-body>
						<div v-if="success" class="text-center pt-2 pb-2">
							<i class="fas fa-check-circle text-size-3 text-success"></i>
							<h3 class="mt-3">Recuperación Enviada</h3>
							<p class="text-size-15 mb-3 text-muted">Las instrucciones para recuperar su cuenta de usuario han sido enviadas a su correo electrónico.</p>
							<p class="text-size-15 mb-0 text-muted">Por favor, revise su bandeja de entrada.</p>
						</div>
						<div v-else>
							<div class="text-center mb-4">
								<!--
								<img src="/img/header-logo.png" class="mb-3" />
								-->
								<img src="@/assets/logo-angamos.png" style="height: 90px;" class="mb-3" />
								<h4 class="mb-0">Recuperar Acceso</h4>
							</div>
							<b-form action="#" @submit.prevent="recover">
								<b-form-group label="Ingrese RUT de Empresa o Persona">
									<b-input v-model="username" size="lg" :state="val_username" :formatter="cleanIdentifier" :maxlength="9"></b-input>
									<b-form-invalid-feedback>Por favor ingrese un RUT válido.</b-form-invalid-feedback>
									<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
								</b-form-group>
								<b-button type="submit" variant="primary" size="lg" block :disabled="!val_username">
									<span>Recuperar Cuenta</span>
								</b-button>
							</b-form>
						</div>
					</b-card-body>
				</b-card>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->
			</b-overlay>

		</div>
	</div>
</template>

<script>
export default {
	name: 'Recover',
	components: {
	},
	data() {
		return {
			loading: false,
			success: false,
			username: null
		}
	},
	mounted() {
	},
	computed: {
		val_username() {
			if (this.username == null) return null
			if (this.username.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.username)) return false
			var dv = this.username.slice(-1)
			var rut = this.username.length == 9 ? this.username.slice(0,8) : this.username.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
	},
	methods: {
		recover() {
			this.loading = true
			this.$store.dispatch('recover', this.username)
			.then(() => {
				this.loading = false
				this.success = true
				this.username = null
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 5000)
			})
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay,
				toaster: 'b-toaster-top-center'
			})
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
	}
}
</script>

<style scoped>
.login-box {
	width: 320px;
	margin: 50px auto 0px;
}
</style>
