<template>
	<Unauthorized v-if="!getters.prv_reports"></Unauthorized>
	<div v-else>
		<SmartSearch :search="smart_search"></SmartSearch>
		<b-row>
			<b-col lg="4" md="6" sm="12" class="mb-4" v-if="getters.prv_reports_monitor">
				<b-card no-body>
					<b-card-body>
						<h5>MONITOREO EN TIEMPO REAL</h5>
						<p class="text-size-12 text-muted mb-0">Visualice los controles realizados a personas.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('Monitor')">
							<strong>Ver Últimos Controles Realizados</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-4" v-if="getters.prv_reports_statistics">
				<b-card no-body>
					<b-card-body>
						<h5>ESTADÍSTICAS</h5>
						<p class="text-size-12 text-muted mb-0">Gráficos resumen con la suma de controles realizados.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="success" button @click="goTo('StatsControl')">
							<strong>Abrir Estadística de Controles</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-4" v-if="true">
				<b-card no-body bg-variant="secondary" text-variant="white">
					<b-card-body>
						<h5 class="text-center mb-3">¿NECESITA MÁS REPORTES?</h5>
						<p class="text-size-11 text-center mb-2">Todos nuestros sistemas se entregan con informes personalizados. Consulte en:</p>
						
						<p class="mb-0 text-size-15 text-center"><i class="fas fa-envelope"></i> contacto@eniwer.cl</p>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>

		<!-- CONTROLS EXPORT MODAL -->
		<b-modal id="excel-export-ready" hide-header size="md">
			<h4 class="mb-2">¡Su reporte está listo!</h4>
			<p class="text-size-12 text-muted mb-3">Presione el botón verde para descargarlo a su equipo.</p>
			<b-button v-if="excel.uuid" :href="api_path+'reports/'+excel.uuid" variant="success" block size="lg">
				<i class="fas fa-file-excel mr-1"></i>
				<span>Descargar Reporte</span>
			</b-button>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
		<!-- /CONTROLS EXPORT MODAL -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import SmartSearch from '@/components/SmartSearch.vue'

export default {
	name: 'Reports',
	components: {
		Unauthorized,
		SmartSearch
	},
	data() {
		return {
			excel: {
				uuid: null
			},
			tables: {
				selected: 'contractors',
				options: [
					{ value: 'contractors', text: 'Empresas Contratistas' },
					{ value: 'subcontractors', text: 'Empresas Subcontratistas' },
					{ value: 'service_orders', text: 'Órdenes de Servicio' },
					{ value: 'contracts', text: 'Contratos' },
					{ value: 'warranties', text: 'Garantías' },
					{ value: 'subcontracts', text: 'Subcontratos' },
					{ value: 'internal_workers', text: 'Trabajadores HMC' },
					{ value: 'contractor_workers', text: 'Trabajadores Contratistas' },
					{ value: 'subcontractor_workers', text: 'Trabajadores Subcontratistas' },
					{ value: 'contractor_drivers', text: 'Conductores Contratistas' },
					{ value: 'subcontractor_drivers', text: 'Conductores Subcontratistas' },
					{ value: 'internal_vehicles', text: 'Vehículos HMC' },
					{ value: 'contractor_vehicles', text: 'Vehículos Contratistas' },
					{ value: 'subcontractor_vehicles', text: 'Vehículos Subcontratistas' },
				],
				loading: false,
				file: null,
			},
			controls: {
				loading: false,
				file: null,
				selected: 1,
				options: [
					{ value: 1, text: 'Día' },
					{ value: 2, text: 'Mes' },
					{ value: 3, text: 'Intervalo' }
				],
				day: '',
				range: {
					start: '',
					end: ''
				},
				month: null,
				months: [
					{ value: 1, text: 'Enero' },
					{ value: 2, text: 'Febrero' },
					{ value: 3, text: 'Marzo' },
					{ value: 4, text: 'Abril' },
					{ value: 5, text: 'Mayo' },
					{ value: 6, text: 'Junio' },
					{ value: 7, text: 'Julio' },
					{ value: 8, text: 'Agosto' },
					{ value: 9, text: 'Septiembre' },
					{ value: 10, text: 'Octubre' },
					{ value: 11, text: 'Noviembre' },
					{ value: 12, text: 'Diciembre' }
				]
			},
			smart_search: {
				input: '',
				loading: false,
				data: []
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		api_path() {
			return this.$store.state.api_path
		}
	},
	mounted() {
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		smartSearch() {
			this.smart_search.loading = true
			this.$api.get('smart_search/'+this.smart_search.input)
			.then(response => {
				this.smart_search.loading = false
				this.smart_search.data = response.data
				this.$bvModal.show('smart-search')
			})
			.catch(error => {
				this.smart_search.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		controlExport() {
			this.controls.loading = true
			this.excel.uuid = null
			this.$api.get('excel/controls', {
				params: {
					option: this.controls.selected,
					day: this.controls.day,
					range_start: this.controls.range.start,
					range_end: this.controls.range.end,
					month: this.controls.month
				}
			})
			.then(response => {
				this.controls.loading = false
				this.excel.uuid = response.data.uuid
				this.$bvModal.show('excel-export-ready')
			})
			.catch(error => {
				this.controls.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		tableExport() {
			this.tables.loading = true
			this.excel.uuid = null
			this.$api.get('excel/tables/'+this.tables.selected)
			.then(response => {
				this.tables.loading = false
				this.excel.uuid = response.data.uuid
				this.$bvModal.show('excel-export-ready')
			})
			.catch(error => {
				this.tables.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		download() {
			this.$api.get('reports/'+this.uuid)
		},
	}
}
</script>